<template>
  <v-col
    cols="12"
    sm="12"
  >
    <fellowships :section="2" />
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import Fellowships from '@/components/Fellowships';

export default {
  name: 'FellowshipsList',
  components: {
    Fellowships,
  },
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      
    },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
